import React from "react"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/saflp2page.module.scss"
import Data from "./data/saflp2page.json"
import FooterLinks from "src/components/footerlinks_language/footer.js"


class saflp2page extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: Data,
      language: props.language
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
      this.state.isChrome = isChrome();
      this.state.isFirefox = isFirefox();
      this.state.isIeEdge = isIeEdge();

      if(isChrome()){
        const browserData = Object.assign(this.state.data, this.state.data.chrome);
        this.setState({data: browserData});
      }
      if(isFirefox()){
        const browserData = Object.assign(this.state.data, this.state.data.firefox);
        this.setState({data: browserData});
      }
      this.setState({ language: this.props.language});
      if (typeof window != "undefined") {
        window.mapParams();
      }
    });
  }

  startAnimation() {
    // Directly set styles for fade-in effect
    const wrapper = document.querySelector(`.${Styles.main}`);
    if (wrapper) {
      wrapper.style.transition = 'opacity 0.5s ease-in-out';
      wrapper.style.opacity = '0';
    }

    const secondPage = document.querySelector(`.${Styles.secondPage}`);
    if (secondPage) {
      secondPage.style.visibility = 'visible';
      secondPage.style.opacity = '1';
      secondPage.style.pointerEvents = 'auto';
      secondPage.style.transition = 'opacity 0.5s ease-in-out, visibility 0s linear 0s';
    }
  }

  render() {
    return (
      <>
      <div id = {Styles.saflp2page} style={{ display: this.state.display }}>
        <div className = {Styles.header}><img src={this.state.data.topLogo} alt="" /></div>
     <div className = {Styles.main}>
       <h1 className = "hc_param">{this.state.data.headline}</h1>
       <h2 className = {Styles.subhead}>{this.state.data.subheadline}</h2>

       <div className = {Styles.stepGrid}>
         <div className = {Styles.step}>1</div>
         <div className = {Styles.copy}>
           <h2>{this.state.data.subhead1}</h2>
           <p>{this.state.data.copy1}</p>
         </div>

         <div className = {Styles.step}>2</div>
         <div className = {Styles.copy}>
           <h2>{this.state.data.subhead2}</h2>
           <p>{this.state.data.copy2}</p>
         </div>
       </div>
       <div className = {Styles.comp}>{this.state.data.compliance1}</div>
       <div className = {Styles.comp}>{this.state.data.compliance2}</div>
       <button className = {`${Styles.download} cl1 saf_dl`} data-cy="cta" onClick = {() => {
         this.startAnimation();
         window.triggerInstall();
       }}>{this.state.data.button}</button>
       {this.props.children}
       </div>
       <div className={Styles.secondPage}>
          <img alt="logo" className={Styles.secondLogo} src={this.state.data.logo} />
          <p className={Styles.secondHeadline}>{this.state.data.secondHeadline}</p>
          <div className={Styles.secondSteps}>
            <ul>
              <li>
                <p className={Styles.stepText}>{this.state.data.step1Text}</p>
                <img alt="step1" src={this.state.data.img1} />
              </li>
              <li>
                <p className={Styles.stepText}>{this.state.data.step2Text}</p>
                <img alt="step2" src={this.state.data.img2} /> 
              </li>
              <li>
                <p className={Styles.stepText}>{this.state.data.step3Text}</p>
                <img alt="step3" src={this.state.data.img3} />
              </li>
            </ul>
          </div>
          <p className={Styles.tryAgain}>{this.state.data.secondTryAgain1} <a href="https://clearbar.app/contact-us/" target="_blank">{this.state.data.secondTryAgain2}</a></p>
          <footer>
            <ul>
              <li>Tightrope Interactive</li>
              <li>
                <a href="https://clearbar.app/terms-of-service/" target="_blank">Terms</a>
              </li>
              <li>
                <a href="https://clearbar.app/privacy-policy/" target="_blank">Privacy</a>
              </li>
              <li>
                <a href="https://clearbar.app/uninstall/" target="_blank">Uninstall</a>
              </li>
              <li>
                <a href="https://clearbar.app/contact-us/" target="_blank">Unsubscribe</a>
              </li>
              <li>
                <a href="https://clearbar.app/contact-us/" target="_blank">Contact</a>
              </li>
              <li>All trademarks are property of their respective owners</li>
              <li>
                <a href="https://clearbar.app/privacy-policy/#cali-consumer" target="_blank">Do Not Sell My Personal Information</a>
              </li>
            </ul>
          </footer>
          </div>
        </div>
      <FooterLinks language={this.state.language}></FooterLinks>
      </>
    )
  }
}
export default saflp2page
